import './App.css';
import React, { Component } from 'react'

import ContactUs from './components/ContactUs';
import Blogs from './components/Blogs';
import About from './components/About';
import GoogleAds from './components/GoogleAds';
import { Provider } from './context/context';


import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


export class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      data: { url: 'https://statelyworld.com/' },
    }
  }


  render() {
    const { data } = this.state;
    return (
      <Provider value={data}>
        <div className="App">
          <Router>
            <div className="jumbotron text-center">
              <Link to={"/app"}>
                <img alt="Stately World" style={{ backgroundColor: "#ffffff", padding: "5px", borderRadius: "4px" }} src={data.url + "wp-content/uploads/2020/09/cropped-stately-5-1024x379-1.png"} /></Link>
              <p style={{ fontSize: "18px" }}>Serving Technology Simply…</p>

              <div className="btn-group">
                <Link to={"/app"} className="btn btn-primary">Blogs</Link>
                <Link to={"/sw-about"} className="btn btn-primary">About</Link>
                <Link to={"/sw-support"} className="btn btn-primary">Support</Link>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-sm-4">
                  <GoogleAds></GoogleAds>
                </div>
                <div className="col-sm-8">
                  <Switch>
                    <Route path="/sw-support" component={ContactUs} />
                    <Route exact path="/app" component={Blogs} />
                    <Route exact path="/sw-about" component={About} />
                  </Switch>
                </div>
              </div>
            </div>
          </Router>
        </div>
      </Provider>
    )
  }
}

export default App
