import React, { Component } from 'react'
import { myContext } from '../context/context';

export class About extends Component {
    static contextType = myContext;
    render() {
        return (
            <div>
                <h3>About</h3>
                <p>Stately World (Serving Technology Simply) is the Software development service with the mission of making digital to everyone. This website also provides <a href={this.context.url}>blogs/articles</a> of various category like Technology, Education, and Computer subjects. The purpose of this website is to provide learning content for the learners.</p>
                <p>To see blogger profile click on below link.</p>
                <a href={this.context.url + "gauravj"} >{this.context.url}gauravj</a><br></br><br></br>
                <p>Please feel free to suggest the topics that you want to cover in the future and We will try our best to do so.</p>
                <h4>Social Media</h4>
                <a href="https://www.instagram.com/statelyworld_official/" className="fa fa-instagram">&nbsp;</a>
                <a href="https://twitter.com/statelyworld" className="fa fa-twitter">&nbsp;</a>
                <a href="https://www.facebook.com/statelyworld/" className="fa fa-facebook">&nbsp;</a>
            </div>
        )
    }
}

export default About
