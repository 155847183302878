import React, { Component } from 'react'
import { myContext } from '../context/context';

export class ContactUs extends Component {
    static contextType = myContext;

    constructor(props) {
        super(props)

        this.state = {
            your_name: '',
            your_email: '',
            your_message: '',
            into: '',
            status: '',
            message: '',
            posted_data_hash: '',
        }
    }


    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }



    mySubmitHandler = (event) => {
        event.preventDefault();
        const data = this.context
        let { your_name, your_email } = this.state;

        const error = <div className="error" style={{ border: "2px solid red" }} >Error! Name and Email are required.</div>;

        if (your_name === '' || your_email === '') {
            this.setState({
                message: error
            });
            return false;

        }

        let formdata = new FormData();
        formdata.append("your_name", this.state.your_name);
        formdata.append("your_email", this.state.your_email);
        formdata.append("your_message", this.state.your_message);


        let requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(data.url + "wp-json/contact-form-7/v1/contact-forms/4645/feedback", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    const error = <div className="error" style={{ border: "2px solid #46b450" }}>{result.message}</div>;

                    this.setState({
                        into: result.into,
                        status: result.status,
                        message: error,
                        posted_data_hash: result.posted_data_hash,
                    });

                    document.getElementById("contact_form_1").reset();
                    setTimeout(() => {
                        this.setState({
                            message: '',
                            your_name: '',
                            your_email: '',
                            your_message: '',
                        });
                    }, 5000);
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }


    render() {
        const { message } = this.state;
        return (
            <div>
                <h3>Support</h3>
                <p>If you have any query feel free to <a href={this.context.url + "contact/"}>contact</a>. You can also email on <a href="mailto:statelyworld@gmail.com">statelyworld@gmail.com</a></p>

                <form onSubmit={this.mySubmitHandler} id="contact_form_1">
                    <div className="form-group">
                        <input type="text" name="your_name" id="your_name" className="your_name form-control" placeholder="Name*" onChange={this.myChangeHandler} />
                    </div>
                    <div className="form-group">
                        <input type="email" name="your_email" id="your_email" className="your_email form-control" placeholder="Email*" onChange={this.myChangeHandler} />
                    </div>
                    <div className="form-group">
                        <textarea name="your_message" cols="40" rows="10" className="your_message form-control" placeholder="Message" onChange={this.myChangeHandler}></textarea>
                    </div>
                    <button type="submit" className="btn btn-danger">Send</button>
                </form>
                {
                    message === '' ? '' : message
                }
            </div>
        )
    }
}

export default ContactUs
