import React, { Component } from 'react'

export class GoogleAds extends Component {
    render() {
        return (
            <div>
                <ins className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-9992617397085971"
                    data-ad-slot="7401084892"
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>
            </div>
        )
    }
}
export default GoogleAds
