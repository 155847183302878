import React, { Component } from 'react';
import { myContext } from '../context/context';

export class Blogs extends Component {

    static contextType = myContext;

    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isLoaded: false,
            blogs: [],
            page: 1,
            nrofpages: 1,
            search: ''
        }
    }


    componentDidMount() {
        this.setPage();
    }

    toggleItem = (e) => {

        let accItem = document.getElementsByClassName('accordionItem');
        let itemClass = e.target.parentNode.className;

        for (let i = 0; i < accItem.length; i++) {
            accItem[i].className = 'accordionItem close';
        }
        if (itemClass === 'accordionItem close') {
            e.target.parentNode.className = 'accordionItem open';
            e.target.parentNode.scrollIntoView()
        }
    }

    handlePrevPage = () => {
        if (this.state.page - 1) {
            this.setState({ page: this.state.page - 1 }, () => {
                this.setPage();
            });
        }
        else {
            this.setState({ page: 1 }, () => {
                this.setPage();
            });
        }

    }
    // Event handler: Increase page count no higher then nrofpages.
    handleNextPage = () => {
        if (this.state.page < this.state.nrofpages) {
            this.setState({ page: this.state.nrofpages }, () => {
                this.setPage();
            });
        }
        else {
            this.setState({ page: this.state.page + 1 }, () => {
                this.setPage();
            });

        }


    }


    setPage = () => {
        const data = this.context
        fetch(data.url + "wp-json/wp/v2/posts/?search=" + this.state.search + "&page=" + this.state.page)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        blogs: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    searchPosts = (event) => {
        let val = event.target.value;
        this.setState({ search: val }, () => {
            if (val.length >= 3) {
                this.setPage();
            }
            else if (val.length === 0) {
                this.setPage();
            }
            else {
                // this.setState({ blogs: [] });
            }
        });

    }


    render() {
        const { error, isLoaded, blogs, search } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <div className="search-container">
                        <input autoComplete="off" type="text" placeholder="Search" name="search" onChange={this.searchPosts} value={search} />
                    </div>
                    <h3>Blogs</h3>
                    <p>Posts, tutorials, snippets, notes, and everything else. The archive of everything We've written.</p>


                    <div className="accordionWrapper">

                        {blogs && blogs.length
                            ? blogs.map((blog, i) => (
                                <div className="accordionItem close" key={blog.id}>
                                    <h4 className="accordionItemHeading" onClick={this.toggleItem}>{++i}. {blog.title.rendered} </h4>
                                    <div className="accordionItemContent" dangerouslySetInnerHTML={{ __html: blog.content.rendered }} />
                                </div>
                            )) : <p>Opps!, nothing matched that search.</p>
                        }

                        <p>Page {this.state.page} of {this.state.nrofpages}</p>
                        <div className="btn-group">
                            <button className="btn btn-primary" onClick={this.handlePrevPage}>Newer posts</button>
                            <button className="btn btn-primary" onClick={this.handleNextPage}>Older posts</button>
                        </div>

                    </div>


                </div>
            );
        }
    }
}

export default Blogs
// https://www.pluralsight.com/guides/return-html-elements-in-json
//https://since1979.dev/basic-post-navigation-with-react-and-the-wp-rest-api/